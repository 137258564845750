





















import Vue from 'vue'
import Component from 'vue-class-component'
import SettlementHeader from '@/components/sysop/billing/Settlement/SettlementHeader.vue'
import SettlementCalculationLines from '@/components/sysop/billing/Settlement/SettlementCalculationLines.vue'
import SettlementInvoiceLines from '@/components/sysop/billing/Settlement/SettlementInvoiceLines.vue'

import { vxm } from '@/store'

@Component({
  components: {
    SettlementHeader,
    SettlementCalculationLines,
    SettlementInvoiceLines,
  },
})
export default class Settlement extends Vue {
  private isLoading = false
  private data = {}

  private get id() {
    return parseInt(this.$route.params?.id) || null
  }

  private created() {
    this.load()
  }

  private load() {
    if (!this.id) {
      this.data = {}
      return
    }
    this.isLoading = true
    this.$axios
      .get('/v4/sysop/billing/settlements/' + this.id)
      .then((response) => {
        this.data = response.data.data || {}
        this.isLoading = false
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Failed to load settlement')
        this.isLoading = false
      })
  }
}
